/* global gapi */

import React from 'react';
import { isMobile } from 'react-device-detect'
import VideoList from './components/VideoList'
import VideoInfo from './components/VideoInfo'
import VideoPlayer from './components/VideoPlayer'

class Index extends React.Component {
  constructor(props) {
    super(props);

    if (isMobile) {
      window.location = 'http://www.youtube.com/bootynonstop'
    }
  }

  componentDidMount() {
    this.getVideoList()
  }

  getVideoList() {
    const start = () => {
      gapi.client.init({
        'apiKey': 'AIzaSyAQEm3n2jpNjaZS8fXpq6cvehj5Kn3tZPc',
      }).then(function () {
        return gapi.client.request({
          'path': 'https://www.googleapis.com/youtube/v3/search?order=date&part=snippet&type=video&channelId=UCu6xSm33KXu8F2vo7UkzYsQ&maxResults=50',
        })
      }).then((response) => {
        this.getVideoDetail(response.result.items.map(item => item.id.videoId));
      }, (reason) => {
        console.log(reason);
      });
    };
    gapi.load('client', start);
  }

  getVideoDetail(videos) {
    gapi.client.init({
      'apiKey': 'AIzaSyAQEm3n2jpNjaZS8fXpq6cvehj5Kn3tZPc',
    }).then(function () {
      return gapi.client.request({
        'path': `https://www.googleapis.com/youtube/v3/videos?id=${videos}&part=snippet,statistics&maxResults=50`,
      })
    }).then((response) => {
      const videos = this.filteredResponse(response.result.items)
      this.props.videoStore.setVideos(videos)
      this.props.videoStore.selectVideo(videos[0])
    }, (reason) => {
      console.log(reason);
    });
  }

  filteredResponse(items) {
    return items.filter((item) => item.kind === "youtube#video" && item.id !== '3CaXnyxnCCI').map((item) => {
      return {
        id: item.id,
        title: item.snippet.title,
        description: item.snippet.description,
        publishedAt: item.snippet.publishedAt,
        viewCount: item.statistics.viewCount
      };
    });
  }

  render() {
    if (isMobile) {
      return null
    }

    const {videoStore} = this.props;
    return (
      <div className="container">
        <VideoPlayer videoStore={videoStore}/>
        <VideoList videoStore={videoStore}/>
        <a className="instagram" href="https://www.instagram.com/bootynonstop/" target="_blank">@bootynonstop</a>
        <VideoInfo videoStore={videoStore}/>
        <div id="disclaimer">* If the rapture should take place, resulting in my absence, it will become necessary for
          tribulation saints to mirror or financially support this site.
        </div>
      </div>
    )
  }
}

export default Index
