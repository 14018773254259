import {observable, action, decorate} from 'mobx'
import _ from 'underscore'

class VideoStore {
  videos = [];
  selectedVideo = {};

  setVideos(videos) {
    this.videos = videos;
  }

  selectVideo(video) {
    this.selectedVideo = video;
  }

  selectNextVideo() {
    const index = _.findIndex(this.videos, (video) => video.id === this.selectedVideo.id)
    if (index + 1 < this.videos.length) {
      this.selectVideo(this.videos[index + 1])
    } else {
      this.selectVideo(this.videos[0])
    }
  }
}

decorate(VideoStore, {
  videos: observable,
  selectedVideo: observable,
  setVideos: action,
  selectVideo: action,
  selectNextVideo: action,
});

export default VideoStore
