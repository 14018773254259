import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import VideoStore from './stores/VideoStore';
import * as serviceWorker from './serviceWorker';

let videoStore = new VideoStore();

ReactDOM.render(<App videoStore={videoStore}/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
