import React from 'react'
import {observer} from 'mobx-react'
import VideoLink from './VideoLink'

class VideoList extends React.Component {
  render() {
    const {videoStore} = this.props;
    return (
      <div className="videos">
        {videoStore.videos.map((video) => {
          return <VideoLink key={video.id} video={video} videoStore={videoStore}/>
        })}
      </div>
    )
  }
}

export default observer(VideoList)