import React from 'react'
import {observer} from 'mobx-react'
import TimeAgo from 'react-timeago'

class VideoInfo extends React.Component {
  render() {
    const {selectedVideo} = this.props.videoStore;
    if (selectedVideo.id) {
      return (
        <div id="info">
          <TimeAgo date={selectedVideo.publishedAt} component="abbr"/>, {selectedVideo.viewCount} views
        </div>
      )
    } else {
      return null;
    }
  }
}

export default observer(VideoInfo)
