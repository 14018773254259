import React from 'react'
import {observer} from 'mobx-react'

class VideoLink extends React.Component {
  onClick(e) {
    e.preventDefault();
    this.props.videoStore.selectVideo(this.props.video);
  }

  selected() {
    return this.props.video && this.props.videoStore.selectedVideo && (this.props.video.id === this.props.videoStore.selectedVideo.id);
  }

  render() {
    const {video} = this.props;
    return (
      <div className={`video ${this.selected() ? 'active' : ''}`}>
        <h2><a href="" onClick={this.onClick.bind(this)}>{video.title}</a></h2>
        <h3>{video.description}</h3>
      </div>
    )
  }
}

export default observer(VideoLink)
