import React from 'react'
import YouTube from 'react-youtube'
import {observer} from 'mobx-react'

class VideoPlayer extends React.Component {
  onEnd() {
    this.props.videoStore.selectNextVideo()
  }

  render() {
    const {selectedVideo} = this.props.videoStore;
    if (selectedVideo.id) {
      return (
        <YouTube id="player"
                 opts={{height: '430', width: '700', playerVars: {autoplay: 1}}}
                 videoId={selectedVideo.id}
                 onEnd={this.onEnd.bind(this)}/>
      )
    } else {
      return null;
    }
  }
}

export default observer(VideoPlayer)
